.slider {
    height: 100%;
    position: relative;

    .list {

        &::after {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
            background: transparent;
        }

        .item {
            position: absolute;
            inset: 0 0 0 0;
            overflow: hidden;
            top: 0;
            left: 0;
            opacity: 0.8;
            width: 100%;
            height: 100%;
            transition: .2s all ease-in-out;

            &.currentItem {
                opacity: 1;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .thumbnail {
        position: absolute;
        bottom: 50px;
        width: 100%;

        .group {
            padding: 0 2rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            column-gap: 1rem;

            .thumbnailItem {
                padding: 0.75rem 0;
                border-bottom: 1px solid #FFFFFF;
                font-size: 18px;
                color: #FFFFFF;
                font-weight: 400;
                flex: 1;
                cursor: pointer;
                position: relative;

                &.activeThumbnail {

                    &::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: -3px;
                        width: 100%;
                        height: 4px;
                        background-color: #1EABE1;
                        animation: slideWidth 3s forwards;
                    }
                }


            }
        }

    }

    .sliderContent {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        overflow: hidden;
        z-index: 99;
        max-width: 1048px;
        width: 100%;

        .sliderIn {
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 3rem;
            height: 100%;

            .content {
                // animation: 0.4s slideUp forwards;

                .heading {
                    font-size: 44px;
                    color: #FFFFFF;
                    margin-bottom: 1rem;
                    white-space: pre-wrap;
                    word-break: break-word;
                }

                .subHeading {
                    color: #FFFFFF;
                    padding-top: 10px;
                    font-size: 18px;
                }
            }
        }
    }
}

@keyframes slideWidth {
    from {
        width: 0%;
    }
}

.banner {
    width: 100%;
    height: 100%;

    &.bg_grey {
        background-color: #DFDFDF;
    }

    .container {

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .bold_text {
            font-size: 44px;
        }
    }
}

.small_text_16 {
    padding: 1rem 0;
    font-size: 16px;
    color: #333333;
    font-weight: 400;
    display: block;
}

.view_more {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    text-decoration: none;
    cursor: pointer;
    padding-top: 1rem;
    border-top: 0.5px solid #333333;

    &:hover {

        .circle {

            svg {
                transform: rotate(90deg);
            }
        }
    }
}

.border_left {
    border-left: 0.5px solid #333333;
}

.pl_3rem {
    padding-left: 3rem;
}

.freight {
    background-color: #DFDFDF;

    .container {

        .group {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            row-gap: 2rem;
            column-gap: 2rem;
            padding: 3rem 0;

            .item {
                display: flex;
                flex-direction: column;
                row-gap: 0.5rem;

                .label {
                    font-size: 18px;
                    font-weight: 500;
                    color: #333333;
                }

                .text {
                    color: #333333;
                    font-size: 14px;
                }
            }
        }
    }
}

.parallax {
    width: 100%;
    height: 100%;
    background-image: url('./../assets/png/parallex.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    .container {

        .group {
            display: flex;
            justify-content: center;
            flex-direction: column;
            height: 100%;
            row-gap: 1rem;

            .heading {
                font-size: 54px;
                color: #FFFFFF;
                font-weight: 800;
            }

            .subHeading {
                font-size: 16px;
                color: #FFFFFF;
                font-weight: 400;
            }
        }
    }
}

.innovative {
    width: 100%;
    background-color: #DFDFDF;

    .container {

        .innoText {
            font-size: 44px;
            line-height: 1.1;
            font-weight: 700;
            color: #333333;
            text-align: center;
            padding: 4rem 0;
        }
    }
}

.bannerOne {
    width: 100%;
    position: relative;
    height: 400px;


    .Image {
        width: 100%;
        height: 100%;
        position: relative;

        &::after {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
            background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
        }

        img {
            inset: 0 0 0 0;
            position: relative;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .screenName {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 48px;
            font-weight: 700;
            color: #FFFFFF;
            z-index: 99;
        }
    }
}

.tableBanner {
    width: 100%;
    padding: 3rem 0;

    .container {

        .heading {
            padding-bottom: 1rem;
        }
    }
}

.table {
    border-collapse: collapse;
    width: 100%;

    .tableRow {
        // vertical-align: top;

        .tableHead {
            padding: 0.5rem 0;
            font-size: 14px;
            font-weight: 400;
            color: #8A8A8A;
            border-bottom: .5px solid #333333;
            text-align: left;
            padding-left: 1rem;

            &:first-child {
                padding-left: 0;
            }


        }

        .tableData {
            padding: 1rem 0;
            border-bottom: 0.5px solid #333333;
            color: #333333;
            font-size: 16px;
            font-weight: 400;
            padding-left: 1rem;

            &:first-child {
                font-size: 18px;
                font-weight: 700;
                white-space: nowrap;
                padding-left: 0;
            }
        }
    }
}

.circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #1EABE1;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        transition: 0.3s all ease-in-out;
    }

    &:hover {

        svg {
            transform: rotate(90deg);
        }
    }

}

.handling {
    width: 100%;

    .container {

        .handlingContent {
            padding: 4rem 0;

            .flex {
                    display: flex;
                    column-gap: 8rem;
            
                    .group {
                        display: flex;
                        flex-direction: column;
                        row-gap: 1rem;
            
                        .itemList {
                            display: flex;
                            flex-direction: column;
            
                            .subHeading {
                                padding: 0.5rem 0;
                                font-size: 1.5rem;
                                color: #333333;
                                font-weight: 500;
                            }
            
                            .item {
                                margin: 0 2rem;
                            }
            
                            .subItem {
                                padding: 0 3rem;
                            }
                        }
                    }
                }
        }

        
    }
}

.formBanner {
    width: 100%;
    padding: 4rem 0;
    flex: 1;

    .container {

        .form {

            width: 100%;

            .formHeading {
                padding-bottom: 1rem;
            }
        }
    }
}

.contactBanner {
    width: 100%;
    padding: 4rem 0;

    .container {

        .contactFlex {
            display: flex;
            align-items: center;
            justify-content: space-between;
            column-gap: 3rem;

            .information {
                flex: 1;

                .informationHeading {
                    font-size: 44px;
                    color: #333333;
                    font-weight: 700;
                    margin-bottom: 1rem;
                }

                .informationContent {
                    font-size: 16px;
                    font-weight: 400;
                    color: #333333;
                    margin-bottom: 1rem;
                }
            }
        }
    }
}

.serviceEnd {
    width: 100%;
    height: 100%;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.mapBanner {
    width: 100%;
    height: 100%;
}

.container {
    max-width: 1140px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

.valueGroup {
    margin-bottom: 1rem;

    .label {
        font-size: 16px;
        color: #333333;
        font-weight: 400;
    }

    .value {
        font-size: 30px;
        font-weight: 700;
        color: #333333;
    }
}

.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 130px;
    min-height: 50px;
    color: #333333;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    background-color: #1EABE1;
    outline: none;
    border: 1px solid transparent;
    transition: 0.3s all ease-in-out;

    &:hover {
        border: 1px solid #1EABE1;
        background-color: transparent;
    }

    .buttonText {
        padding-left: 1rem;
    }
}

.formGroup {
    display: block;
    margin-bottom: 1rem;

    .formControl {
        padding: 0.75rem 0;
        outline: none;
        border: none;
        border-bottom: .5px solid #333333;
        width: 100%;

        &::placeholder {
            font-family: 'Sarabun', sans-serif;
        }

        &:focus {
            outline: none;
        }
    }
}

textarea {
    min-height: 150px;
    resize: none;
}

//responsive css 

@media screen and (max-width: 768px) {
    .slider {

        .thumbnail {
            display: none;
        }

        .sliderContent {

            .sliderIn {
                flex-direction: column;
                row-gap: 2rem;
                text-align: center;
            }
        }
    }

    .banner {
        height: auto;

        .container {
            max-width: 280px;

            .content {
                flex-direction: column;
                row-gap: 2rem;

                .bold_text {
                    font-size: 37px;
                }
            }

            .border_left {
                border: none;
                padding: 0;
            }
        }
    }

    .handling {

        .container {

            .flex {
                flex-direction: column;
                row-gap: 2rem;
            }
        }
    }

    .table {

        .tableRow {

            .tableData {

                .button {
                    min-width: 90px;
                    white-space: nowrap;
                    padding: 0 0.5rem;
                    font-size: 12px;
                }

                &:first-child {
                    white-space: inherit;
                }
            }
        }
    }

    .contactBanner {
        height: auto;

        .container {
            max-width: 280px;

            .contactFlex {
                flex-direction: column;
                row-gap: 2rem;
            }
        }
    }

    .freight {

        .container {
            max-width: 280px;

            .group {
                flex-wrap: wrap;
            }
        }
    }

    .parallax {

        .container {
            max-width: 280px;

            .group {
                .heading {
                        font-size: 37px;
                    }
            }
        }
    }

    .tableBanner {

        .container {
            max-width: 280px;
        }
    }

    .handling {

        .container {
            max-width: 280px;
        }
    }

    .formBanner {

        .container {
            max-width: 280px;
        }
    }

    .screenName {
        white-space: nowrap;
    }

    .innoText {
        font-size: 37px !important;
    }
}

@media screen and (max-width: 992px) {
    .slider {

        .thumbnail {
            display: none;
        }
    }

    .banner {
        height: auto;

        .container {
            flex-direction: column;
            row-gap: 1rem;
            padding: 2rem 0;

            .border_left {
                border: none;
                padding: 0;
            }
        }
    }

    .handling {

        .container {

            .flex {
                flex-direction: column;
                row-gap: 2rem;
            }
        }
    }

    .table {

        .tableRow {

            .tableData {

                &:first-child {
                    white-space: inherit;
                }
            }
        }
    }

    .contactBanner {
        height: auto;

        .container {

            .contactFlex {
                flex-direction: column;
                row-gap: 2rem;
            }
        }
    }

    .freight {

        .container {

            .group {
                flex-wrap: wrap;
            }
        }
    }

    .arrow {
        display: none !important;
    }
}


.leftArrow {
    opacity: 1;
    transform: translate(48px, -45px);
    top: 50%;
    left: 0px;

}

.rightArrow {
    opacity: 1;
    transform: translate(-138px, -45px) rotate(180deg) skew(360deg, 0deg);
    top: 50%;
    left: 100%;
}

.arrow {
    background: transparent;
    width: 90px;
    height: 90px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    border-radius: 50%;

    svg {
        fill: #ffffff;
        overflow: visible;
        transform: translateX(0);
        transition: transform .3s ease;

        .rect {
            width: 34px;
            height: 2px;
            transition: width .3s ease;
        }

        .rectHorizontal {
            width: 0px;
            height: 2px;
            transition: width .3s ease;
        }
    }

    &::after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-color: #1EABE1;
        border-radius: 50%;
        position: absolute;
        z-index: -1;
        opacity: 0;
        transform: translateX(-5px) scale(0);
        transition: transform .3s cubic-bezier(.1, 0, .3, 1), opacity .3s ease;
    }

    &:hover {

        svg {

            transform: translateX(-9px);

            .rect {
                width: 20px;
            }

            .rectHorizontal {
                width: 32px;
            }
        }

        &::after {
            opacity: 1;
            transform: translateX(-5px) scale(1);
        }
    }
}

.flex {
    display: flex;
}

.alignItemsCenter {
    align-items: center;
}

.flexOne {
    flex: 1;
    padding-right: 10px;
}

.h_full {
    height: 100%;
}

.flex_col {
    flex-direction: column;
}

.textRight {
    text-align: right;
}

@keyframes slideUp {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.linkPrimary {
    color: #1EABE1 !important;
}