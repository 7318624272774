.w_full {
    width: 100% !important;
}

.h_full {
    height: 100% !important;
}

.relative {
    position: relative;
}

.ml_auto {
    margin-left: auto;
}

.flex {
    display: flex;
}

.align_center {
    align-items: center;
}

a {
    text-decoration: none;
}

.customCursor {
    position: fixed;
    top: 0;
    left: 0;
    width: 12px;
    height: 12px;
    background-color: #1EABE1;
    border-radius: 50%;
    pointer-events: none;
    z-index: 555;
}

.flex_col {
    flex-direction: column;
}