*,
*::before,
*::after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    cursor: none !important;
}

html,
body,
#root {
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
    font-family: 'Sarabun', sans-serif;
}

body {
    overflow-x: hidden;
    /* cursor: none !important; */
}
